import React, {useEffect} from "react";
import ServiceCollection from "src/services/ServiceCollection";
import {Box, Button, PropertyFilterProps, SpaceBetween} from "@amzn/awsui-components-react";
import {Container, Grid, Header, SelectProps} from "@amzn/awsui-components-react/polaris";
import TPTaxCwbReportFilterSelector from "src/components/reports/cwbReport/TPTaxCwbReportFilterSelector";
import {FilterOption, FilterProperty, GetTPReportFiltersRequest} from "src/models/reports/GetTPReportFilters";
import CONSTANTS from "src/utils/constants";
import TPPropertyFilter from "src/components/shared/TPPropertyFilter";

export default function TPTaxCwbReportView(props: { services: ServiceCollection }) {
    const {services} = props;
    const [selectedAccountingDayOption, setSelectedAccountingDayOption] = React.useState(null as SelectProps.Option | null);
    const [selectedWorkbook, setSelectedWorkbook] = React.useState(null as SelectProps.Option | null);
    const [selectedAccountingPeriod, setSelectedAccountingPeriod] = React.useState(null as SelectProps.Option | null);
    const [filtersDataRequestPayload, setFiltersDataRequestPayload] = React.useState(null as GetTPReportFiltersRequest | null);
    const [filtersData, filtersDataLoading, filtersDataError] = props.services.reportsService.getTPReportFilters(filtersDataRequestPayload);
    const [filterProperties, setFilterProperties] = React.useState([] as FilterProperty[]);
    const [filterOptions, setFilterOptions] = React.useState([] as FilterOption[]);
    const [query, setQuery] = React.useState<PropertyFilterProps.Query>({
        tokens: [],
        operation: "and"
    });

    useEffect(() => {
        if (!filtersDataError) {
            return;
        }
        props.services.messageService.showErrorAutoDismissBanner(filtersDataError);
    }, [filtersDataError]);

    useEffect(() => {
        if (filtersData) {
            setFilterProperties(filtersData.filterProperties);
            setFilterOptions(filtersData.filterOptions);
        }
    }, [filtersData]);

    return (
        <SpaceBetween direction="vertical" size="l">
            <Grid gridDefinition={[{colspan: 10}, {colspan: 2}]}>
                <TPTaxCwbReportFilterSelector
                    services={services}
                    selectedWorkbook={selectedWorkbook}
                    selectedAccountingPeriod={selectedAccountingPeriod}
                    selectedAccountingDay={selectedAccountingDayOption}
                    onWorkbookSelected={(workbookOption) => setSelectedWorkbook({
                        label: workbookOption?.label,
                        value: workbookOption?.value
                    })}
                    onAccountingPeriodSelected={(accountingPeriodOption) => setSelectedAccountingPeriod({
                        label: accountingPeriodOption?.label,
                        value: accountingPeriodOption?.value
                    })}
                    onAccountingDaySelected={(accountingDayOption) => setSelectedAccountingDayOption({
                        label: accountingDayOption?.label,
                        value: accountingDayOption?.value
                    })}
                />
                <Box float="left" padding={{top: "xl"}}>
                    <Button
                        onClick={() => {
                            setFiltersDataRequestPayload({
                                reportName: CONSTANTS.REPORT_TYPES.TP_TAX_CWB_REPORT,
                                params: {
                                    accountingDay: selectedAccountingDayOption?.value as string,
                                    period: selectedAccountingPeriod?.value as string,
                                    workbookId: selectedWorkbook?.value as string
                                }
                            });
                        }}
                        disabled={(!selectedAccountingDayOption || !selectedAccountingPeriod || !selectedWorkbook)}
                        loading={false}
                        variant="primary"
                    >
                        View Report
                    </Button>
                </Box>
            </Grid>
            <Container
                header={
                    <>
                        <Header variant="h2"
                                actions={
                                    <SpaceBetween direction="horizontal" size="xs">
                                        <Button disabled={true}>Download Filtered Report</Button>
                                        <Button>
                                            Download Report
                                        </Button>
                                    </SpaceBetween>
                                }>
                            Report Details
                        </Header>
                        <Grid
                            gridDefinition={[{colspan: 9}, {colspan: 3}]}
                        >
                            <TPPropertyFilter
                                filterProperties={filterProperties}
                                filterOptions={filterOptions}
                                filtersDataLoading={filtersDataLoading}
                                query={query}
                                setQuery={setQuery}
                            />
                        </Grid>
                    </>
                }
            />
        </SpaceBetween>
    );
}
